.dialogbackground{
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
} 

.dialogwindow {
  width: 500px;
  height: 400px;

  position: fixed;
  top: 20%;
  left: 50%;

  margin-left: -250px;

  background-color:#242424;
  z-index: 1;
  border-radius: 10px;

  overflow: hidden;
}

.dialoglist{
  overflow: hidden;
  /*height: 100%;*/
  margin: 20px;
  border-radius: 10px;
}
