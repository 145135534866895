.dialogrow{
  width: 100%;
  height: 50px;
  background-color: #363636;
  user-select: none;
  border-bottom: solid 1px #242424;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.dialogrow:last-child {
  border: none;
}

.dialogrow:hover {
  background-color: #4c4c4c;;
}

.dialogrowtitle{
  color: var(--text-colour);
  font-size: var(--medium-text);
  /*
  vertical-align: middle;
  line-height: 50px;
  text-align:left;
  margin-left: 10px; 
 
  position: relative;
  top: -25px;
  left: 55px;
   */
}

