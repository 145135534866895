  .headerbar {
	background-color: var(--header-colour);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 40px;
}

.headerbar-title {
    position: absolute;
}

.headerbar-menu {
    position: absolute;
    right: 0px;
}

