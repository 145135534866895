:root {
  /*
This section holds the variables for the design of the css. 
variables can be recalled using var(--var-name);
*/
  --mainbg-color: #06c;
  --accent-color: #F78F3F;
  --border-colour: #d3d3d3;
  --shadow-colour: #888888;
  --active-colour: #38B44A;
  --alert-colour: #f44336;
  --passive-colour: #008CBA;
  --inactive-colour: #8a8a8a;
  --header-colour:#29415e;
  --footer-colour:#29415e;
  /* toolbar */
  --toolbar-hover-colour: rgba(255, 255, 255, 0.15);
  /* dialog / popover */
  --dialog-header-colour: #29415e;
  --dialog-bg-colour: #383838;
  --dialog-hover-colour: #f7f7f7;
  /* List  */
  --list-bg-colour:#B3B3B3;
  /* Text */
  --titletext-colour: #ffffff;
  --text-colour: #ffffff;
  --text-contrast-color: #ffffff;
  /* Text Size */
  --xs-text: 10px;
  --small-text: 12px;
  --medium-text: 16px;
  --large-text: 22px;
  --xl-text: 30px;
  /* Layout */
  --margin-size: 5px;
  --padding: 10px;
  --border-radius: 5px;
}

.DesignWeb {
  height: 100%;
  width: 100%;
}

html,
body {
  height: 100%;
  overflow: hidden;
}
